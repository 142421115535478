import { useCallback, useEffect, useRef, useState } from "react";
import { useAuth } from "../../../auth/useAuth";
import { TVerificationRequest } from "../models";
import { useVerificationRequestSubmit } from "./useVerificationRequestSubmit";

export const useVerificationRequestSubmitWithPhone = (): [
  TVerificationRequest | null,
  boolean,
  Error | null,
  (id: string, readyVerificationRequest?: TVerificationRequest) => void,
  boolean,
  () => void
] => {
  const idRef = useRef<string | null>(null);
  const { limited } = useAuth();
  const [signinOpen, setSigninOpen] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [result, loading, error, submit] = useVerificationRequestSubmit();

  const handleSigninClose = useCallback(() => {
    setSigninOpen(false);
    setLocalLoading(false);
    idRef.current = null;
  }, []);

  const request = useCallback(
    (id: string, readyVerificationRequest?: TVerificationRequest) => {
      if (limited) {
        setLocalLoading(true);
        setSigninOpen(true);
        idRef.current = id;
      } else {
        submit(id, readyVerificationRequest);
      }
    },
    [limited, submit]
  );

  useEffect(() => {
    if (!idRef.current || limited) {
      return;
    }
    submit(idRef.current);
    handleSigninClose();
  }, [limited, submit, handleSigninClose]);

  return [
    result,
    localLoading || loading,
    error,
    request,
    signinOpen,
    handleSigninClose,
  ];
};
