import { Box, Stack, Typography } from "@mui/material";
import { Select, TextField } from "mui-rff";
import { useTranslation } from "react-i18next";
import { required, requiredCustom } from "../../../../../utils/validation";
import { FileInput } from "../../../../ui/components/FileInput";
import { CardId } from "../../../../../assets/icons";
import { ChangeEvent, SetStateAction, useEffect, useState } from "react";
import { useForm } from "react-final-form";
import { useOnBoardingFormContext } from "../../../hooks/useOnBoardingFormContext";
import { useMultiInputs } from "../../../hooks/useMultiInputs";
import { CustomMultiSelect } from "../../../../ui/components/CustomMultiSelect";

const source_of_wealth = [
  {
    name: "Employment income (salary/bonus) per year",
  },
  {
    value: "Employment income (salary/bonus) 0 – 50’000 per year",
    label: "0 – 50’000 ",
    parent: "Employment income (salary/bonus) per year",
  },
  {
    value: "Employment income (salary/bonus) 50’001 to 100’000 per year",
    label: "50’001 to 100’000",
    parent: "Employment income (salary/bonus) per year",
  },
  {
    value: "Employment income (salary/bonus) 100’001 - 200’000 per year",
    label: "100’001 - 200’000",
    parent: "Employment income (salary/bonus) per year",
  },
  {
    value: "Employment income (salary/bonus) 200’000 - 500’000 per year",
    label: "200’000 - 500’000",
    parent: "Employment income (salary/bonus) per year",
  },
  {
    value: "Employment income (salary/bonus) 500’000 – 1’000’000 per year",
    label: "500’000 – 1’000’000",
    parent: "Employment income (salary/bonus) per year",
  },
  {
    value: "Employment income (salary/bonus) over 1’000’000 per year",
    label: "Over 1’000’000",
    parent: "Employment income (salary/bonus) per year",
  },
  {
    value: "Maturity life insurance policy",
    label: "Maturity life insurance policy",
  },
  {
    value: "Sale of investments/liquidation investment portfolio",
    label: "Sale of investments/liquidation investment portfolio",
  },
  {
    value: "Sale of property/assets",
    label: "Sale of property/assets",
  },
  {
    value: "Inheritance",
    label: "Inheritance",
  },
  {
    value: "Business income (profits)",
    label: "Business income (profits)",
  },
  {
    value: "Gift",
    label: "Gift",
  },
  {
    value: "Virtual asset mining",
    label: "Virtual asset mining",
  },
  {
    value: "Virtual asset trading",
    label: "Virtual asset trading",
  },
  // {
  // name: 'Virtual asset trading',
  // },
  // {
  // value: 'Other exchanges used',
  // label: 'Other exchanges used',
  // parent: 'Virtual asset trading',
  // },
  // {
  // value: 'Years of experience trading',
  // label: 'Years of experience trading',
  // parent: 'Virtual asset trading',
  // },
  // {
  // value: 'Addresses used at other trading sites',
  // label: 'Addresses used at other trading sites',
  // parent: 'Virtual asset trading',
  // },
  {
    value: "Other",
    label: "Other (please specify)",
  },
];

const expected_volume_of_transactions = [
  // {
  //   value: "Less than 1000",
  //   label: "Less than 1000",
  // },
  {
    value: "Up to 1,000",
    label: "Up to 1,000",
  },
  {
    value: "1,000 - 10,000",
    label: "1,000 - 10,000",
  },
  {
    value: "10,000 - 25,000",
    label: "10,000 - 25,000",
  },
  {
    value: "25,000 - 50,000",
    label: "25,000 - 50,000",
  },
  {
    value: "50,000 - 100,000",
    label: "50,000 - 100,000",
  },
  {
    value: "100,000 - 250,000",
    label: "100,000 - 250,000",
  },
  {
    value: "250,000 - 500,000",
    label: "250,000 - 500,000",
  },
  {
    value: "500,000 - 1 Mln.",
    label: "500,000 - 1 Mln.",
  },
  {
    value: "1 Mln. - 5 Mln.",
    label: "1 Mln. - 5 Mln.",
  },
  {
    value: "5 Mln. - 10 Mln.",
    label: "5 Mln. - 10 Mln.",
  },
  {
    value: "Over 10 Mln.",
    label: "Over 10 Mln.",
  },
];

const source_of_funds = [...source_of_wealth];

const estimated_total_wealth = [...expected_volume_of_transactions];
const estimated_values = estimated_total_wealth.map((obj) => obj.value);

export const FinancialInformation = () => {
  const [isDocumentsRequired, setIsDocumentsRequired] = useState(false);
  // const [isSourceExplainShow, setIsSourceExplainShow] = useState(false);
  const [sourceFunds, setSourceFunds] = useState<string[]>([]);
  const [expainSourceFunds, setExpainSourceFunds] = useState<string>("");
  const [sourceWealth, setSourceWealth] = useState<string[]>([]);
  const [expainSourceWealth, setExpainSourceWealth] = useState<string>("");
  const [isDescriptionReq, setIsDescriptionReq] = useState(false);
  const {
    files: { FinancialInformation },
    uploadedFiles,
    formData,
  } = useOnBoardingFormContext();
  const { filesCount, isMaximumInputs, handleAddInput, handleDeleteInput } =
    useMultiInputs(
      Object.keys(
        Object.values(formData).find(
          (el: any) => el.documentType === "FinancialInformation"
        )?.files || {}
      ).length,
      10
    );
  const form = useForm();

  const { t } = useTranslation("onBoarding", {
    keyPrefix: "FinancialInformation.fields",
  });

  const checkIsDocumentsRequired = (volume: string, wealth: string) => {
    return [
      "50,000 - 100,000",
      "100,000 - 250,000",
      "250,000 - 500,000",
      "500,000 - 1 Mln.",
      "1 Mln. - 5 Mln.",
      "5 Mln. - 10 Mln.",
      "Over 10 Mln.",
    ].includes(volume);
  };

  const handleVolumeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLButtonElement;

    setIsDescriptionReq(
      [
        "25,000 - 50,000",
        "50,000 - 100,000",
        "100,000 - 250,000",
        "250,000 - 500,000",
        "500,000 - 1 Mln.",
        "1 Mln. - 5 Mln.",
        "5 Mln. - 10 Mln.",
        "Over 10 Mln.",
      ].includes(target?.value)
    );
    setIsDocumentsRequired(
      checkIsDocumentsRequired(
        target?.value,
        form.getState().values.data.estimated_total_wealth
      )
    );
  };

  const handleEstimatedChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLButtonElement;
    // setIsDocumentsRequired(
    //   checkIsDocumentsRequired(
    //     form.getState().values.data.expected_volume_of_transactions,
    //     target?.value
    //   )
    // );
  };

  const handleDetailedDescChange = () => {
    if (!isDescriptionReq) {
      const plannedInvest =
        form.getState().values.data.expected_volume_of_transactions;
      setIsDescriptionReq(
        [
          "25,000 - 50,000",
          "50,000 - 100,000",
          "100,000 - 250,000",
          "250,000 - 500,000",
          "500,000 - 1 Mln.",
          "Over 1 Mln.",
        ].includes(plannedInvest)
      );
    }
  };

  const handleSourceExplainChange = (
    event: ChangeEvent<HTMLInputElement>,
    formKey: "source_of_funds" | "source_of_wealth"
  ) => {
    const target = event.target as HTMLButtonElement;
    const newExplain = target?.value;

    const setExplainFunc =
      formKey === "source_of_funds"
        ? setExpainSourceFunds
        : setExpainSourceWealth;
    const source = formKey === "source_of_funds" ? sourceFunds : sourceWealth;

    setExplainFunc(newExplain);

    const indexOfOther = source.indexOf("Other");
    let sourceCopy = JSON.parse(JSON.stringify(source));
    if (indexOfOther > -1) {
      sourceCopy = sourceCopy.filter((elem: string) => elem !== "Other");
      sourceCopy.push("Other");
    }
    let newVal =
      sourceCopy
        ?.map((el: string) => (el === "Other" ? "Other: " : el))
        .join(", ") + newExplain;

    form.change("data." + formKey, newVal);
  };

  const handleSourceChange = (
    event: ChangeEvent<HTMLInputElement>,
    cb: (value: SetStateAction<string[]>) => void
  ) => {
    const target = event.target as HTMLButtonElement;
    let values = event.target.value as unknown as string[];
    const newValue = values[values.length - 1];
    if (newValue.includes("Employment income")) {
      cb((prev) => {
        if (prev.length < values.length) {
          values = [
            ...prev.filter(
              (prevItem) => !prevItem.includes("Employment income")
            ),
            newValue,
          ];
        }
        return values;
      });
    } else {
      cb(values);
    }

    const indexOfOther = values.indexOf("Other");
    if (indexOfOther > -1) {
      values = values.filter((elem) => elem !== "Other");
      values.push("Other");
    }
    const explain =
      target?.name === "data.source_of_funds"
        ? expainSourceFunds
        : expainSourceWealth;
    const newVal = values
      ?.map((el: string) => (el === "Other" ? "Other: " + explain : el))
      .join(", ");

    form.change(target?.name, newVal);
  };

  useEffect(() => {
    const currency = form.getFieldState("data.currency")?.value;
    if (!currency) form.change("data.currency", "EUR");
  }, [form]);

  useEffect(() => {
    const variantsModel = [
      "expected_volume_of_transactions",
      "estimated_total_wealth",
    ];
    variantsModel.forEach((variant) => {
      const currentVal = form.getState().values.data?.[variant] || "";
      if (currentVal && !estimated_values.includes(currentVal)) {
        form.change("data." + variant, "");
      }
    });
  }, [form]);

  useEffect(() => {
    const variantsModel = [
      {
        data: source_of_funds,
        key: "source_of_funds",
        cb: setSourceFunds,
        setExplain: setExpainSourceFunds,
      },
      {
        data: source_of_wealth,
        key: "source_of_wealth",
        cb: setSourceWealth,
        setExplain: setExpainSourceWealth,
      },
    ];
    variantsModel.forEach((variant) => {
      const currentVal = form.getState().values.data?.[variant?.key] || "";
      let subStr = currentVal;
      const otherIndex = currentVal?.indexOf("Other");
      if (otherIndex > -1) {
        subStr = subStr.substring(0, otherIndex + 5);
        const explain = currentVal.substring(otherIndex + 6).trim();
        variant.setExplain(explain);
        form.change("data.del_" + variant.key, explain);
      }
      const array =
        subStr.length > 0
          ? subStr.split(", ").filter((el: string) => el !== "")
          : [];
      variant.cb(array);
    });
  }, [form]);

  return (
    <Stack spacing={2} width='100%' marginBottom={6}>
      <Select
        name='data.expected_volume_of_transactions'
        label={t("expected_volume_of_transactions")}
        data={expected_volume_of_transactions}
        required
        fieldProps={{ validate: required }}
        inputProps={{ onChange: handleVolumeChange }}
      />
      <Box>
        <CustomMultiSelect
          name='data.source_of_funds'
          label={t("source_of_funds")}
          data={source_of_funds}
          required
          validate={required}
          onChange={handleSourceChange}
          values={sourceFunds}
          setValue={setSourceFunds}
        />
        <TextField
          name='data.source_of_funds'
          label={t("source_of_funds")}
          sx={{ display: "none" }}
        />
        <TextField
          name='data.del_source_of_funds'
          label={t("source_explain")}
          required={sourceFunds.includes("Other")}
          sx={{
            marginTop: "16px",
            display: sourceFunds.includes("Other") ? "block" : "none",
          }}
          defaultValue={expainSourceFunds}
          inputProps={{
            onChange: (e: ChangeEvent<HTMLInputElement>) =>
              handleSourceExplainChange(e, "source_of_funds"),
          }}
        />
        <Typography variant='caption' paddingLeft='12px'>
          {t("field_additions.source_of_funds")}
        </Typography>
      </Box>

      <Select
        name='data.estimated_total_wealth'
        label={t("estimated_total_wealth")}
        data={estimated_total_wealth}
        required
        fieldProps={{ validate: required }}
        inputProps={{ onChange: handleEstimatedChange }}
      />
      <Box>
        <CustomMultiSelect
          MenuProps={{ sx: { height: window.innerWidth < 400 ? 600 : "auto" } }}
          data={source_of_wealth}
          name='data.source_of_wealth'
          label={t("source_of_wealth")}
          required
          validate={required}
          onChange={handleSourceChange}
          values={sourceWealth}
          setValue={setSourceWealth}
        />
        <TextField
          name='data.source_of_wealth'
          label={t("source_of_wealth")}
          sx={{ display: "none" }}
        />
        <TextField
          name='data.del_source_of_wealth'
          label={t("source_explain")}
          required={sourceWealth.includes("Other")}
          sx={{
            marginTop: "16px",
            display: sourceWealth.includes("Other") ? "block" : "none",
          }}
          inputProps={{
            onChange: (e: ChangeEvent<HTMLInputElement>) =>
              handleSourceExplainChange(e, "source_of_wealth"),
          }}
        />
        <Typography variant='caption' paddingLeft='12px'>
          {t("field_additions.source_of_wealth")}
        </Typography>
      </Box>

      <TextField
        name='data.description_source_of_funds'
        label={t("description_source_of_funds")}
        multiline
        required={isDescriptionReq}
        inputProps={{ onChange: handleDetailedDescChange }}
      />

      <TextField name='data.tax_id' label={t("tax_id")} />

      <Box>
        <TextField
          name='data.crypto_wallets'
          label={t("crypto_wallets")}
          multiline
        />
        <Typography
          variant='caption'
          component='p'
          paddingLeft='12px'
          marginTop='4px'
        >
          {t("field_additions.crypto_wallets")}
        </Typography>
      </Box>

      {filesCount.map((count) => {
        if (count === 1) {
          return (
            <FileInput
              handleAddInput={
                filesCount.length === count ? handleAddInput : undefined
              }
              isDeleteBan={uploadedFiles[`FINANCIAL_INFORMATION_DOCUMENT_1`]}
              document={
                FinancialInformation?.[`FinancialInformationFile__Page1`]
              }
              namePrefix={`files.FINANCIAL_INFORMATION_DOCUMENT_1`}
              label={t("documents")}
              subtitle={t("documents_subtitle")}
              icon={CardId}
              height={121}
              validate={
                isDocumentsRequired
                  ? (e) =>
                      requiredCustom(
                        FinancialInformation?.[
                          `FinancialInformationFile__Page1`
                        ]?.dataLink ?? e,
                        "Please provide documentary proof of your funds to the amount exceeding the planned investment on this platform"
                      )
                  : undefined
              }
              // для применения изменения validate
              key={isDocumentsRequired ? "required" : "notRequired"}
            />
          );
        } else {
          return (
            <FileInput
              handleAddInput={
                filesCount.length === count ? handleAddInput : undefined
              }
              handleDeleteInput={(e: Event, cb) =>
                handleDeleteInput(e, count, cb)
              }
              isDeleteBan={
                uploadedFiles[`FINANCIAL_INFORMATION_DOCUMENT_${count}`]
              }
              document={
                FinancialInformation?.[`FinancialInformationFile__Page${count}`]
              }
              isMaximumInputs={isMaximumInputs}
              namePrefix={`files.FINANCIAL_INFORMATION_DOCUMENT_${count}`}
              label={t("documents")}
              subtitle={t("documents_subtitle")}
              icon={CardId}
              height={121}
              key={count}
            />
          );
        }
      })}
    </Stack>
  );
};
