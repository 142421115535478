import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { WidgetLayout } from "../../../../components/WidgetLayout";

import { useGetQRCode } from "../../hooks/useGetQRCode";
import { useOnBoardingContext } from "../../hooks/useOnBoardingContext";
import { FC, useEffect, useState } from "react";
import { useCheckUser } from "../../hooks/useCheckEmail";
import useLocalStorage from "../../../ui/hooks/useLocalStorage";
import { AppleStore, GooglePlay, CompleteKyc } from "../../../../assets/icons";
import { APP_STORE_URL, GP_URL, MOBILE_APP_URL } from "../../models";
import { QrTypeDialog } from "./QrModal";

type TDeviceOs = "Android" | "iOS" | "Other";
type TPopapType = null | "goToApp" | "downloadApp";

export const QRScreen: FC<{ email: string | undefined }> = ({ email }) => {
  const { requestId, requestLoading, requestError } = useOnBoardingContext();
  const [data, qrLoading, qrError] = useGetQRCode(requestId || undefined);
  const [userEmail, setUserEmail] = useLocalStorage("email", null);
  const [user, userLoading, userError] = useCheckUser(userEmail);
  const [deviceOs, setDeviceOs] = useState<TDeviceOs>("Other");
  const [isQRPopapOpen, setIsQRPopapOpen] = useState(false);
  const [popapType, setPopapType] = useState<TPopapType>(null);

  const getMobileOS = () => {
    const ua = navigator.userAgent;
    if (/android/i.test(ua)) {
      setDeviceOs("Android");
    } else if (/iPad|iPhone|iPod/.test(ua)) {
      setDeviceOs("iOS");
    } else {
      setDeviceOs("Other");
    }
  };

  const handleOpenModal = (type: TPopapType) => {
    setIsQRPopapOpen(true);
    setPopapType(type);
  };

  useEffect(() => {
    getMobileOS();
  }, []);

  const onPressToVerificartion = () => {
    if (deviceOs !== "Other") {
      try {
        window.location.replace(`${MOBILE_APP_URL}verificationId=${requestId}`);
      } catch (e) {
        if (deviceOs === "Android") {
          setTimeout(() => {
            window.location.replace(GP_URL);
          }, 1000);
        } else if (deviceOs === "iOS") {
          setTimeout(() => {
            window.location.replace(APP_STORE_URL);
          }, 1000);
        }
      }
    }
  };

  useEffect(() => {
    if (email || user?.email) {
      setUserEmail(email || user.email);
    }
  }, [user, setUserEmail, email]);

  const loading = qrLoading || requestLoading || userLoading;
  const error = qrError || requestError || userError;

  return (
    <WidgetLayout title='Complete your KYC'>
      <Stack
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        spacing={3}
      >
        {loading && <CircularProgress size={50} />}
        {error !== null && (
          <Alert severity='error'>{error.message || error.toString()}</Alert>
        )}
        {data && (
          <>
            <Box
              sx={{
                minWidth: deviceOs !== "Other" ? "345px" : "496px",
                padding: deviceOs === "Other" ? "40px" : "20px",
                background: "#fff",
                boxShadow:
                  "0px 14.770000457763672px 40.39707946777344px 0px #0000000a, 0px 6.139999866485596px 16.778879165649414px 0px #00000008, 0px 2.2200000286102295px 6.068591594696045px 0px #00000006",
                borderRadius: "12px",
              }}
            >
              <Stack direction='column' alignItems='center'>
                <Typography
                  textAlign={"center"}
                  fontWeight='bold'
                  fontSize={deviceOs === "Other" ? 24 : 20}
                >
                  Complete with app in two clicks!
                </Typography>
                <img
                  style={{
                    width: deviceOs === "Other" ? 272 : 212,
                    height: deviceOs === "Other" ? 256 : 196,
                  }}
                  src={CompleteKyc}
                  alt='complete kyc'
                />
                <Stack
                  direction={deviceOs === "Other" ? "row" : "column"}
                  spacing={deviceOs === "Other" ? 4 : 2}
                >
                  <Button
                    sx={{
                      width: 170,
                      height: deviceOs === "Android" ? "48px" : "44px",
                      padding: "11px 24px",
                    }}
                    variant='contained'
                    onClick={
                      deviceOs !== "Other"
                        ? onPressToVerificartion
                        : () => handleOpenModal("goToApp")
                    }
                  >
                    Go to App
                  </Button>
                  {deviceOs === "Other" ? (
                    <Button
                      sx={{
                        width: 170,
                        backgroundColor: "#000",
                        padding: "11px 24px",
                      }}
                      variant='contained'
                      onClick={() => handleOpenModal("downloadApp")}
                    >
                      Download App
                    </Button>
                  ) : deviceOs === "Android" ? (
                    <a href={GP_URL} target='_blank' rel='noreferrer'>
                      <img
                        style={{
                          borderRadius: "16px",
                          width: "170px",
                        }}
                        src={GooglePlay}
                        alt='googleplay link'
                      />
                    </a>
                  ) : (
                    <a href={APP_STORE_URL} target='_blank' rel='noreferrer'>
                      <img
                        style={{
                          borderRadius: "16px",
                          width: "170px",
                          height: "44px",
                        }}
                        src={AppleStore}
                        alt='app store link'
                      />
                    </a>
                  )}
                </Stack>
              </Stack>
            </Box>
            <Stack alignItems='center' justifyContent='center'>
              <Typography textAlign='center'>or</Typography>
              <Button
                variant='text'
                sx={{ textTransform: "initial", fontWeight: 700 }}
                component={Link}
                to={
                  email || userEmail || user?.email
                    ? "/onboarding-form"
                    : "/add-email"
                }
              >
                Continue to Web Version
              </Button>
            </Stack>
            <QrTypeDialog
              data={data}
              onClose={() => setIsQRPopapOpen(false)}
              open={isQRPopapOpen}
              type={popapType}
            />
          </>
        )}
      </Stack>
    </WidgetLayout>
  );
};
