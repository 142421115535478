import { request } from "../../../network/mainClient/request";
import { getDocumentData } from "../../onBoarding/network";
import { TCapsuleResult } from "../models";

export const createCapsule = async (
  sortedAdresses: string[],
  companyId: string
): Promise<TCapsuleResult> => {
  const response = await request({
    path: `/api/v1/document-registry/capsule`,
    method: "POST",
    body: {
      document_addresses: sortedAdresses,
      user_to: companyId,
      fingerprint: "",
    },
  });

  if (response.ok) {
    return response.data;
  }

  throw new Error(response.errorMessage);
};

export const transferCapsule = async (
  requestId: string,
  companyId: string,
  documents: any,
  fingerprints: any
) => {
  const documentIds =
    typeof documents[0] === "string"
      ? documents
      : documents?.map(
          (d: { document: { documentId: string } }) => d.document.documentId
        );
  let reqs = await Promise.all(
    documentIds?.map(async (documentId: string) => {
      const docData = await getDocumentData(documentId);
      const fingerprint = fingerprints[`fingerprints_${documentId}`];

      let address = fingerprint?.[0]?.address;

      if (!address && docData?.document?.dataAddress) {
        address = docData?.document?.dataAddress;
      }

      return { address, docData };
    })
  );
  reqs = reqs?.reduce((acc, val) => acc.concat(val.address), []);

  const sortedAdresses = reqs;

  const createCapsuleRes = await createCapsule(sortedAdresses, companyId);
  return createCapsuleRes;
};
