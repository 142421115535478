import { useCallback, useEffect } from "react";
import { TFormData } from "../../onBoarding";
import useLocalStorage from "./useLocalStorage";
import { FROM_SHARING, STEPPER_COMPLETED } from "../../onBoarding/models";

export const useStepper = (stepIndex: number, formData?: TFormData) => {
  const [completed, setCompleted] = useLocalStorage(STEPPER_COMPLETED, {});
  const [fromSharing, setFromSharing] = useLocalStorage(FROM_SHARING, false);

  useEffect(() => {
    if (formData) {
      const newCompleted = { ...completed };
      Object.keys(formData).forEach((step) => {
        newCompleted[Number(step)] = true;
        setCompleted(newCompleted);
      });
    } else if (fromSharing) {
      setCompleted({});
      setFromSharing(false);
    }
  }, [formData, fromSharing]);

  const handleComplete = useCallback(() => {
    const newCompleted = { ...completed };
    newCompleted[Number(stepIndex)] = true;
    setCompleted(newCompleted);
  }, [stepIndex]);

  return [completed, handleComplete];
};
