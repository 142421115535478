import { useCallback, useState } from "react";

const useLocalStorage = (key: string, defaultValue: any) => {
  const [localStorageValue, setLocalStorageValue] = useState(() => {
    try {
      const value = localStorage.getItem(key);

      if (value) {
        return JSON.parse(value);
      } else {
        localStorage.setItem(key, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (error) {
      localStorage.setItem(key, JSON.stringify(defaultValue));
      return defaultValue;
    }
  });

  const setLocalStorageStateValue = useCallback(
    (valueOrFn: any) => {
      let newValue;
      if (typeof valueOrFn === "function") {
        const fn = valueOrFn;
        newValue = fn(localStorageValue);
      } else {
        newValue = valueOrFn;
      }
      localStorage.setItem(key, JSON.stringify(newValue));
      setLocalStorageValue(newValue);
    },
    [key]
  );

  return [localStorageValue, setLocalStorageStateValue];
};

export default useLocalStorage;
