import { useCallback, useState } from "react";
import { TVerificationRequest, VerificationRequestStatus } from "../models";
import {
  getDocumentFingerPrintsById,
  getVerificationRequest,
  sendVerificationRequest,
  submitVerificationRequest,
} from "../network";
import { transferCapsule } from "../../capsule/network";

const waitStatus = (
  id: string,
  status: VerificationRequestStatus
): Promise<TVerificationRequest> => {
  return new Promise((resolve, reject) => {
    const loop = async () => {
      try {
        const result = await getVerificationRequest(id);
        if (result.status === status) {
          resolve(result);
        } else {
          setTimeout(loop, 15000);
        }
      } catch (e) {
        reject(e);
      }
    };
    setTimeout(loop, 15000);
  });
};

export const useVerificationRequestSubmit = (): [
  TVerificationRequest | null,
  boolean,
  Error | null,
  (id: string, readyVerificationRequest?: TVerificationRequest) => void
] => {
  const [result, setResult] = useState<TVerificationRequest | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const request = useCallback(
    async (id: string, readyVerificationRequest?: TVerificationRequest) => {
      if (!id) {
        setError(new Error("Submit failed. id is not provided"));
        return;
      }

      setIsLoading(true);
      setError(null);
      let verificationRequest: TVerificationRequest;
      try {
        let result: TVerificationRequest;
        verificationRequest =
          readyVerificationRequest || (await getVerificationRequest(id));

        if (verificationRequest.status === VerificationRequestStatus.New) {
          result = await submitVerificationRequest(id);
          if (
            result.status !== VerificationRequestStatus.AllowSendToVerification
          ) {
            await waitStatus(
              id,
              VerificationRequestStatus.AllowSendToVerification
            );
          }
        }
        result = await sendVerificationRequest(id);
        const documents = verificationRequest?.documents;
        const fingerprints: { [key: string]: any } = {};
        for (let i = 0; i < documents!.length; i++) {
          const fingerprint = await getDocumentFingerPrintsById(
            id,
            documents![i].document.documentId
          );

          fingerprints[`fingerprints_${documents![i].document.documentId}`] =
            fingerprint?.Items;
        }

        if (
          result.status === VerificationRequestStatus.Accepted ||
          result.status === VerificationRequestStatus.SentToVerification
        ) {
          await transferCapsule(id, result.companyId!, documents, fingerprints);
        }
        setResult(result);
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        } else {
          setError(new Error("Unknown error"));
        }
      }

      setIsLoading(false);
    },
    []
  );

  return [result, isLoading, error, request];
};
