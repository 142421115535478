import { Alert, Box, Button, Stack, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { WidgetLayout } from "../../../../components/WidgetLayout";
import { useRequestEmail } from "../../../../auth/useEmailRequest";
import { useConfirmEmail } from "../../../../auth/useConfirmEmail";
import { useNavigate } from "react-router-dom";
import { parseSignInError } from "../../../../utils/parseSignInError";
import { ResendSms } from "../../../../screens/SigninScreen/ResendSms";
import useLocalStorage from "../../../ui/hooks/useLocalStorage";

export const AddEmailScreen = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [salt, setSalt] = useState("");
  const [, setUserEmail] = useLocalStorage("email", null);
  const [codeResult, codeLoading, codeError, codeRequest] = useRequestEmail();
  const [confirmResult, confirmLoading, confirmError, confirmRequest] =
    useConfirmEmail();

  const error = codeError || confirmError;

  const onSubmit = useCallback(async () => {
    if (step === 0) {
      codeRequest(email);
      setUserEmail(email);
    } else if (step === 1) {
      confirmRequest(email, code, salt);
    }
  }, [email, code, codeRequest, setUserEmail, confirmRequest, salt, step]);

  useEffect(() => {
    if (codeResult) {
      setSalt(codeResult.salt);
      setStep(1);
    }
    if (confirmResult) {
      navigate("/onboarding-form");
    }
  }, [codeResult, step, confirmResult, navigate]);

  const resendCode = useCallback(() => {
    if (email) {
      return codeRequest(email);
    }
  }, [codeRequest, email]);

  return (
    <WidgetLayout
      title={
        step === 0
          ? "Please enter your e-mail address"
          : "Enter confirmation code"
      }
      subtitle={
        step === 0
          ? "We will send a confirmation code on it"
          : `Sent to ${email}.`
      }
      action={
        step === 1 && (
          <Button
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              lineHeight: "24px",
              textAlign: "center",
              color: "#2968EF",
              textTransform: "capitalize",
            }}
            onClick={() => setStep(0)}
          >
            Change
          </Button>
        )
      }
    >
      <Stack
        width='100%'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "120px",
          }}
        >
          {step === 0 ? (
            <TextField
              fullWidth
              required
              type='email'
              value={email}
              label='E-mail'
              onChange={(e) => setEmail(e.target.value)}
            />
          ) : step === 1 ? (
            <TextField
              fullWidth
              required
              value={code.trim()}
              label='Code'
              type='tel'
              onChange={(e) => setCode(e.target.value)}
            />
          ) : (
            <></>
          )}
        </Box>
        {step === 1 && <ResendSms onResend={resendCode} />}
        <Box mt='18px'>
          <Button
            sx={{
              textTransform: "inherit",
              backgroundColor: "#2968EF",
            }}
            disabled={
              step === 0 ? !email || codeLoading : !code || confirmLoading
            }
            variant='contained'
            onClick={onSubmit}
          >
            Continue
          </Button>
        </Box>
        <Box mt={2} />
        {error !== null && (
          <Alert severity='error'>
            {parseSignInError(
              error.toString(),
              "The code has expired. Please resend it and enter the new one."
            )}
          </Alert>
        )}
      </Stack>
    </WidgetLayout>
  );
};
