import { Alert, Button, Stack } from "@mui/material";
import { useCallback, useEffect, useMemo } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { WidgetLayout } from "../../../../components/WidgetLayout";
import { DocumentForm } from "../../components/DocumentForm";
import { useFormStep } from "../../hooks/useFormStep";
import { useOnBoardingStepUpload } from "../../hooks/useOnBoardingStepUpload";
import {
  FROM_SHARING,
  TInitialStatesModel,
  VerificationAppDocumentType,
} from "../../models";
import { useOnBoardingFormContext } from "../../hooks/useOnBoardingFormContext";
import useLocalStorage from "../../../ui/hooks/useLocalStorage";
import { useOnBoardingContext } from "../../hooks/useOnBoardingContext";
import { useStepper } from "../../../ui/hooks/useStepper";
import { fixDateFormat } from "../../utils/fixDate";

const fixDateFormatLoop = (data: any) => {
  if (Array.isArray(data)) {
    data.forEach((item) => fixDateFormatLoop(item));
  } else if (
    typeof data === "object" &&
    data !== null &&
    !(data instanceof File) &&
    !(data instanceof FileList)
  ) {
    for (const key in data) {
      if (data[key] instanceof Date) {
        data[key] = fixDateFormat(data[key]);
      } else if (typeof data[key] === "object" && data[key] !== null) {
        fixDateFormatLoop(data[key]);
      } else if (typeof data[key] === "string") {
        data[key] = fixDateFormat(data[key]);
      }
    }
  }
};

export const OnBoardingFormStepDocumentScreen = () => {
  const { stepIndex, documentType, type } = useParams<{
    stepIndex: string;
    documentType: string;
    type: "default" | "sharing";
  }>();
  const { t } = useTranslation("onBoarding");
  const { stepData, stepSubmit, readyToSend, goToPrevious, countOfSteps } =
    useFormStep(stepIndex || "-1");
  const { isDisabledContinue, setIsSharing, setIsSharingNeedFilling } =
    useOnBoardingFormContext();
  const [result, loading, error, upload, sendUiData] =
    useOnBoardingStepUpload();
  const [phone] = useLocalStorage("phone", null);
  const [email] = useLocalStorage("email", null);
  const [fromSharing] = useLocalStorage(FROM_SHARING, false);
  const { reload } = useOnBoardingContext();
  const [completed, handleComplete] = useStepper(Number(stepIndex));
  const navigate = useNavigate();

  const initialStatesModel: TInitialStatesModel[] = useMemo(
    () => [
      {
        documentType: "UserInformation",
        data: {
          phone_number: phone,
          email: email,
        },
      },
    ],
    [phone, email]
  );

  const goBackToSharing = useCallback(() => {
    setIsSharing(true);
    setIsSharingNeedFilling(false);
    navigate("../sharing");
  }, [setIsSharing, setIsSharingNeedFilling, navigate]);

  const onSubmit = useCallback(
    async (formValues, form) => {
      handleComplete();
      const formState = form.getState();
      if (formValues.documentType === "UserInformation") {
        formValues.data.email = email;
      }
      if (documentType === "AmlStep") {
        await sendUiData(documentType);
        await reload();
        stepSubmit();
      } else if (formState.dirty) {
        fixDateFormatLoop(formValues);
        upload(
          {
            documentType,
            ...formValues,
          },
          type
        );
      } else {
        stepSubmit();
      }
    },
    [documentType, stepSubmit, upload, sendUiData, handleComplete, email, type]
  );

  useEffect(() => {
    if (result) {
      stepSubmit(result, type === "sharing");
      if (type === "sharing") {
        goBackToSharing();
      }
    }
  }, [result, stepSubmit, goBackToSharing, type]);

  let initialValues = useMemo(() => {
    if (stepData && stepData.documentType !== documentType) return {};
    return (
      stepData ||
      (documentType !== undefined &&
        initialStatesModel.find((el) => el.documentType === documentType)) ||
      {}
    );
  }, [documentType, initialStatesModel, stepData]);

  return (
    <WidgetLayout
      title={t(`${documentType}.title`)}
      subtitle={t(`${documentType}.subtitle`)}
      currentStep={Number(stepIndex)}
      completed={completed}
      type={type}
      countOfSteps={countOfSteps}
    >
      <Stack flexDirection='column' justifyContent='center' alignItems='center'>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, invalid, values }) => (
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <DocumentForm
                documentType={documentType as VerificationAppDocumentType}
              />
              <Stack
                justifyContent='center'
                alignItems='center'
                width='100%'
                mt={4}
                spacing={2}
              >
                <Stack direction='row' spacing={2}>
                  {!readyToSend &&
                    stepIndex &&
                    parseInt(stepIndex) > 0 &&
                    type !== "sharing" && (
                      <Button
                        variant='contained'
                        disabled={loading}
                        onClick={() => goToPrevious(stepIndex)}
                        sx={{
                          width: "140px",
                          height: "44px",
                          textTransform: "none",
                        }}
                      >
                        {t("back")}
                      </Button>
                    )}

                  {stepIndex === "0" && fromSharing ? (
                    <Button
                      variant='contained'
                      disabled={loading || isDisabledContinue}
                      onClick={goBackToSharing}
                      color='secondary'
                      sx={{
                        width: "140px",
                        height: "44px",
                        textTransform: "none",
                      }}
                    >
                      Back to Sharing
                    </Button>
                  ) : null}
                  <Button
                    variant='contained'
                    disabled={loading || isDisabledContinue}
                    type='submit'
                    sx={{
                      width: type !== "sharing" ? "140px" : "initial",
                      height: "44px",
                      textTransform: "none",
                    }}
                  >
                    {!readyToSend
                      ? t(
                          stepIndex && parseInt(stepIndex) + 1 >= countOfSteps
                            ? "to review"
                            : type !== "sharing"
                            ? "continue"
                            : "Save and go to Review"
                        )
                      : t("Back to review")}
                  </Button>
                </Stack>

                {!loading && error && (
                  <Alert severity='error'>
                    {error.message || error.toString()}
                  </Alert>
                )}
              </Stack>
            </form>
          )}
        />
        {/* <pre>{JSON.stringify(initialValues, null, 2)}</pre> */}
      </Stack>
    </WidgetLayout>
  );
};
