import {
  Box,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { WidgetLayout } from "../../../../components/WidgetLayout";
import { useEffect, useMemo, useState } from "react";
import { useOnBoardingFormContext } from "../../hooks/useOnBoardingFormContext";
import { ReactComponent as CheckmarkIcon } from "../../../../assets/icons/checkmark.svg";
import { getVerificationRequest, getVerificationsList } from "../../network";
import { getSortDescending } from "../../../../utils/sortingFunctions";
import { VerificationRequestStatus } from "../../models";
import { StatusView } from "../../../ui/components/StatusView";
import { useOnBoardingContext } from "../../hooks/useOnBoardingContext";

export const OnBoardingFormTermsScreen = () => {
  const { formConfig, setLastAcceptedVerification, isSharing } =
    useOnBoardingFormContext();
  const { request } = useOnBoardingContext();
  const navigate = useNavigate();
  const [verificationsLoading, setVerificaionsLoading] = useState(true);
  const acceptanceConfig = useMemo(() => {
    return formConfig?.rules;
  }, [formConfig]);

  useEffect(() => {
    getVerificationsList()
      .then(async (list) => {
        const verification = getSortDescending([...list], "updatedAt")?.find(
          (verification) =>
            verification.status === VerificationRequestStatus.Accepted
        );
        if (verification) {
          const fullVerification = await getVerificationRequest(
            verification.id
          );
          setLastAcceptedVerification(fullVerification);
        }
        return verification;
      })
      .then((verification) => {
        if (
          (!acceptanceConfig?.showAcceptanceScreen && !verification) ||
          request?.documents?.length ||
          !isSharing
        ) {
          navigate("review");
        }
      })
      .finally(() => setVerificaionsLoading(false));
  }, [
    setLastAcceptedVerification,
    acceptanceConfig,
    navigate,
    isSharing,
    request,
  ]);

  if (verificationsLoading) {
    return <StatusView fullScreen loading={verificationsLoading} />;
  }
  return (
    <WidgetLayout title='Complete your KYC' subtitle=''>
      <Stack
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        spacing={3}
      >
        <List>
          <ListItem alignItems='flex-start'>
            <ListItemIcon>
              <CheckmarkIcon />
            </ListItemIcon>
            <ListItemText>
              I am not a resident of the following countries{" "}
              {formConfig?.rules?.bannedCountries?.join(", ")}
            </ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckmarkIcon />
            </ListItemIcon>
            <ListItemText>I am 18 or older</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckmarkIcon />
            </ListItemIcon>
            <ListItemText>I accept Oneboard Terms and Conditions</ListItemText>
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <CheckmarkIcon />
            </ListItemIcon>
            <ListItemText>I accept Oneboard Privacy Policy</ListItemText>
          </ListItem>
        </List>
        <Box mb='32px'>
          <Button
            sx={{
              textTransform: "inherit",
              backgroundColor: "#2968EF",
            }}
            variant='contained'
            component={Link}
            to='review'
          >
            Continue
          </Button>
        </Box>
      </Stack>
    </WidgetLayout>
  );
};
