export const parseSignInError = (str: string, customMessage?: string) => {
  let errorMessage = str.substring(str.indexOf("/400") + 5).split("%")[0];
  if (errorMessage === "invalid phone format") {
    return `Please enter phone number in International format`;
  }
  if (errorMessage.includes("invalid email address")) {
    return `Please enter valid email address`;
  }
  if (errorMessage === "wrong passcode") {
    return `The code is wrong`;
  }
  return customMessage ? customMessage : errorMessage;
};
