import Dialog from "@mui/material/Dialog";
import { DialogContent, Stack, Typography } from "@mui/material";
import QRCode from "react-qr-code";
import { CloseModalIcon, StoreOneBoard } from "../../../../../assets/icons";
import { ModalCloseIcon } from "./styles";

type TPopapType = null | "goToApp" | "downloadApp";

export type Props = {
  onClose: () => void;
  data: any;
  open: boolean;
  type: TPopapType;
};

export const QrTypeDialog = (props: Props) => {
  const { onClose, open, data, type } = props;

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": {
          minWidth: "496px",
          borderRadius: "16px",
          padding: "36px 32px 56px 32px",
        },
      }}
      maxWidth='xs'
      open={open}
      id='document-review-dialog'
      onClose={onClose}
      keepMounted
    >
      <DialogContent sx={{ padding: 0 }}>
        <ModalCloseIcon src={CloseModalIcon} onClick={onClose} />
        <Stack alignItems='center'>
          <Typography textAlign={"center"} fontWeight='bold' fontSize={16}>
            {type === "goToApp" ? "Continue on your phone" : "Download App"}
          </Typography>
          <Typography
            sx={{ marginBottom: 4 }}
            textAlign={"center"}
            fontSize={14}
          >
            Scan QR code via
            {type === "goToApp" ? " OneBoard app" : " your phone"}
          </Typography>
          {type === "goToApp" ? (
            <QRCode value={data.data} size={200} />
          ) : (
            <img width={200} src={StoreOneBoard} alt='store qr' />
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
